import { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import DOMPurify from 'dompurify';

import { requestBlogDetails } from './utils';
import plotGraph from './graphs/graphController';
import Graph from './GraphNew/Graph';

const BlogDetailsPage = () => {
  const { slug } = useParams();
  const blogContentRef = useRef(null);
  const [blog, setBlog] = useState({});
  const [graphIds, setGraphIds] = useState([]);
  const [blogContentReady, setBlogContentReady] = useState(false);

  const isBlogLoaded = Object.keys(blog).length > 0;

  useEffect(() => {
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
      if (node.nodeName === 'A' && 'target' in node) {
        node.setAttribute('target', '_blank');
      }
    });
  }, []);

  useEffect(() => {
    const getBlog = async () => {
      try {
        const blogDetails = await requestBlogDetails(slug);
        setBlog(blogDetails);
      } catch (error) {}
    };
    getBlog();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isBlogLoaded) return;

    window.MathJax?.typeset();
    plotGraph(slug);

    if (blogContentRef.current) {
      const graphs = blogContentRef.current.querySelectorAll('[data-graph]');

      const graphIds = [];
      graphs.forEach(graph => {
        graph.textContent = '';
        graphIds.push(graph.id);
      });

      setGraphIds(graphIds);
      setBlogContentReady(true);
    }
  }, [isBlogLoaded, slug]);

  return (
    <>
      <Helmet>
        <title>{blog.page_title}</title>
        <meta name="description" content={blog.meta_description} />
      </Helmet>
      <article className="prose prose-slate prose-xl break-words mx-auto px-4 sm:px-6 lg:px-8 mt-12 mb-8 prose-figure:my-0 prose-figcaption:mt-1 prose-h2:text-primary-600 prose-h2:text-[1.65rem] prose-h3:text-[1.25rem] prose-h4:text-[1rem] prose-h5:text-[0.8rem] prose-h6:text-[0.6rem]">
        <span className="block text-base text-primary-600 font-semibold tracking-wide uppercase">
          Blogs
        </span>
        <h1 className="mb-4 mt-2">{blog.title}</h1>
        <div className="not-prose">
          <p className="text-lg">
            By <span className="text-primary-600">{blog.author}</span> |{' '}
            {blog.date}
          </p>
        </div>
        <p className="mt-3 text-sm">
          <em>
            This page may contain some affiliate links. This means that, at no
            additional cost to you, Alpha Investing Group will earn a commission
            if you click through and make a purchase.{' '}
            <Link to="/privacy-policy" target="_blank">
              Learn more
            </Link>
          </em>
        </p>
        <img src={blog.image} alt={blog.title} className="mt-8 mb-8" />
        {isBlogLoaded && (
          <article
            ref={blogContentRef}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(blog.content_ckeditor, {
                ADD_TAGS: ['iframe'],
                ADD_ATTR: ['target', 'rel'],
              }),
            }}
          ></article>
        )}
      </article>
      {blogContentReady &&
        graphIds.map(graphId => <Graph key={graphId} graphId={graphId} />)}
    </>
  );
};
export default BlogDetailsPage;
